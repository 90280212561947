import { useUserScopedKey } from '@/features/workspace';
import { tryParseJson } from '@erp-mobile/utils';
import { useMutation } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useEffect, useMemo, useState } from 'react';
import type {
  FinishInventoryTaskPayload,
  ManualInventorySkuParams,
  ManualInventorySkuWithRealStockDto,
} from './manual-inventory.dto';
import { manualInventoryService } from './manual-inventory.service';
import { addListToConfirmData } from './manual-inventory.utils';

/**
 * 获取SKU库存信息
 */
export const useGetSkuStockInfo = () => {
  return useMutation({
    mutationFn: async (params: ManualInventorySkuParams) => {
      return await manualInventoryService.getSkuStockInfo(params);
    },
  });
};

/**
 * 结束盘点
 */
export const useFinishManualInventory = () => {
  return useMutation({
    mutationFn: async (payload: FinishInventoryTaskPayload) => {
      await manualInventoryService.finishInventoryTask(payload);
    },
  });
};

/**
 * 获取选中的仓库id，同时设置选中的仓库id
 * 目前：手动修改仓位、任务修改仓位、手动盘点 共用一套设置
 */
export const useSkuWarehouseId = () => {
  const [warehouseId, setWarehouseId] = useState<number>();

  useEffect(() => {
    manualInventoryService.getSelectedWarehouseId().then((id) => {
      if (id) {
        setWarehouseId(+id);
      }
    });
  }, []);

  /**
   * 保存选中的仓库id
   */
  const saveWarehouseId = async (id: number) => {
    await manualInventoryService.setSelectedWarehouseId(id);
    setWarehouseId(id);
  };

  return {
    warehouseId,
    setWarehouseId,
    saveWarehouseId,
  };
};

/**
 * 本地存储数据
 *
 * why: 不使用 atomWithStorage
 *
 * atom 从 localStorage 获取到数据后，通过 setState 更新 state，
 * 状态更新是异步的，会再下一次 render 时才能获取到最新的 state
 * 这样会在初始化时，拿不到正确的 state。
 */
export const useStorageData = () => {
  const key = useUserScopedKey('wh', 'manual-inventory', 'unconfirm-data');

  const getData = () => {
    const dataStr = localStorage.getItem(key) ?? void 0;
    const data = tryParseJson<ManualInventorySkuWithRealStockDto[]>(dataStr);

    return data;
  };

  const saveData = (data: ManualInventorySkuWithRealStockDto[]) => {
    localStorage.setItem(key, JSON.stringify(data));
  };

  return {
    getData,
    saveData,
  };
};

export const useConfirmData = () => {
  const key = useUserScopedKey('wh', 'manual-inventory', 'confirm-data');

  // 使用 memo 避免重复创建 atom，导致无限循环
  const confirmDataAtom = useMemo(() => {
    return atomWithStorage<ManualInventorySkuWithRealStockDto[]>(key, []);
  }, [key]);

  const [confirmData, setConfirmData] = useAtom(confirmDataAtom);

  // 返回统计信息
  // totalSkuCode: 已盘点种类
  // totalRealStock: 已盘点数量
  const statistics = useMemo(() => {
    const skuCodeSet = new Set<string>();
    let totalRealStock = 0;

    confirmData.forEach((item) => {
      skuCodeSet.add(item.sku_code);
      totalRealStock += item.real_stock;
    });

    return {
      totalSkuCode: skuCodeSet.size,
      totalRealStock,
    };
  }, [confirmData]);

  const addConfirmDataList = (data: ManualInventorySkuWithRealStockDto[]) => {
    setConfirmData((prev) => {
      return addListToConfirmData(data, prev);
    });
  };

  const clearConfirmData = () => {
    setConfirmData([]);
  };

  const getData = () => {
    const dataStr = localStorage.getItem(key) ?? void 0;
    const data = tryParseJson<ManualInventorySkuWithRealStockDto[]>(dataStr);

    return data;
  };

  return {
    confirmData,
    getData,
    addConfirmDataList,
    clearConfirmData,
    statistics,
  };
};
