/**
 * 尝试解析 JSON 字符串，解析失败返回 undefined
 * @param value string
 * @returns
 */
export function tryParseJson<T = unknown>(value?: string): T | undefined {
  if (!value) {
    return;
  }

  try {
    return JSON.parse(value) as T;
  } catch {
    return;
  }
}
